<template>
  <div style="padding-top: 50px;width: 100%;">
    <LeftTitle title="技术资源"/>
    <div class="technology-resources-css animated fadeInUp">
      <div class="technology-resources-img">
        <img v-for="item in technologyResources.imgUrlList" :src="item" alt="部分技术支援">
      </div>
      <div class="technology-resources-content"><span>{{ technologyResources.content }}</span></div>
    </div>
  </div>
</template>

<script>
import {technologyResources} from "@/api/company"
import LeftTitle from "@/components/library/base-left-title.vue";

export default {
  components: {LeftTitle},
  setup() {
    return {technologyResources}
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
}

</script>

<style lang='less' scoped>
.technology-resources-css {
  padding-left: 10vh;
  padding-right: 10vh;
  width: 100%;
  height: 100%;

  .technology-resources-img {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
      margin-right: 20px;
    }

    :after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .technology-resources-content {
    margin-top: 30px;
    margin-left: 60px;
    font-size: 18px;
    font-weight: bolder;
  }
}
</style>
